import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { TextInputWithLabel } from '@/src/common/components/elements/Forms/TextInputWithLabel'
import { Button, StyledH6 } from './StyledComponents'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import * as yup from 'yup'
import { FormErrorDisplay } from '@/src/modules/build-a-card/FormErrorDisplay'
import { NEW_LOGIN, PHONE_SIGN_IN_BTN_ID } from './LoginConstants'
import AuthCode from 'react-auth-code-input'
import { useLoginContext } from './LoginContext'
import { inputHasError } from '@/src/common/utilities/inputHasError'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import toggleChatBot from '@/src/common/utilities/toggleChatBot'
import { ButtonStyledAsAnchor } from '@/src/common/components/elements/Forms/StyledComponents'

const phoneNumberSchema = yup.object({
    phoneNumber: yup
        .string()
        .trim()
        .required('Required')
        .length(14, 'Must be a valid US phone number'),
    optIn: yup.boolean().oneOf([true], 'Required'),
})

const verificationCodeSchema = yup.object({
    verificationCode: yup
        .string()
        .trim()
        .required('Required')
        .min(6, 'Enter the full verification code'),
})

type TPhoneProps = {
    setIsLoginSuccess?: Function
}

const PhoneNumber = ({ setIsLoginSuccess }: TPhoneProps) => {
    // Test phone number & code: +1 (345)-678-9101 --> 123456
    const {
        actionText,
        loginType,
        showVerificationScreen,
        setShowVerificationScreen,
        phoneVerificationText,
        phoneNumber,
        setPhoneNumber,
        loading,
        setLoading,
    } = useLoginContext()

    const [verificationCode, setVerificationCode] = useState<string>('')
    const [optIn, setOptIn] = useState<boolean>(false)
    const [resentVerification, setResentVerification] = useState<{
        message: string
        type: 'error' | 'success'
    } | null>(null)

    const [phoneNumberFormErrors, setPhoneNumberFormErrors] = useState<
        yup.ValidationError[]
    >([])
    const [verificationCodeFormErrors, setVerificationCodeFormErrors] =
        useState<yup.ValidationError[]>([])

    const clearFormErrorForField = (path: string) => {
        setPhoneNumberFormErrors(
            phoneNumberFormErrors.filter((x) => x.path !== path)
        )

        setVerificationCodeFormErrors(
            verificationCodeFormErrors.filter((x) => x.path !== path)
        )
    }

    useEffect(() => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(
                firebaseAuth,
                PHONE_SIGN_IN_BTN_ID,
                {
                    size: 'invisible',
                    callback: () => {
                        return
                    },
                    'expired-callback': () => {
                        console.log('expired captcha')
                    },
                }
            )
        } catch (e: any) {
            if (e?.code === 'auth/argument-error') {
                console.log('no sign in button')
            }
        }
    }, [])

    const handleSignUp = async (isResend: boolean) => {
        // must be this format: '+1 (345)-678-9101'
        isResend && setResentVerification(null)

        const isValid = await phoneNumberSchema.isValid(
            {
                phoneNumber,
                optIn: loginType === NEW_LOGIN ? optIn : true,
            },
            { abortEarly: false }
        )

        if (!isValid) {
            return phoneNumberSchema
                .validate(
                    {
                        phoneNumber,
                        optIn: loginType === NEW_LOGIN ? optIn : true,
                    },
                    { abortEarly: false }
                )
                .then(() => setPhoneNumberFormErrors([]))
                .catch((e) => setPhoneNumberFormErrors(e.inner))
        }

        setLoading(true)

        const formattedPhoneNumber = '+1 ' + phoneNumber.replace(' ', '-')

        const appVerifier: RecaptchaVerifier = window.recaptchaVerifier

        try {
            window.confirmationResult = await signInWithPhoneNumber(
                firebaseAuth,
                formattedPhoneNumber,
                appVerifier
            )

            // if resending verification, set success message
            if (isResend) {
                setResentVerification({
                    message: 'A new verification code has been sent!',
                    type: 'success',
                })
                return
            }

            setShowVerificationScreen(true)
        } catch (e: any) {
            // if user is requesting resend, set error message
            if (isResend) {
                setResentVerification({
                    message:
                        'Unable to resend phone verification code. Please try again later.',
                    type: 'error',
                })
                return
            }

            if (e?.code === 'auth/captcha-check-failed') {
                const unauthorizedError: yup.ValidationError = {
                    name: 'Unauthorized',
                    path: 'phoneNumber',
                    message: 'Unauthorized domain',
                    value: 'Unauthorized domain',
                    inner: [],
                    errors: [],
                }
                setPhoneNumberFormErrors([unauthorizedError])
                return
            }

            if (e?.code === 'auth/too-many-requests') {
                const invalidError: yup.ValidationError = {
                    name: '',
                    path: 'phoneNumber',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                }
                setPhoneNumberFormErrors([invalidError])
                return
            }

            const generalError: yup.ValidationError = {
                name: '',
                path: 'phoneNumber',
                message:
                    'Something went wrong. Please try again later or click Chat Now to get assistance from our GiftGuide.',
                value: 'general error',
                inner: [],
                errors: [],
            }

            setPhoneNumberFormErrors([generalError])
        } finally {
            setLoading(false)
        }
    }

    const confirmCode = async () => {
        const validCode = await verificationCodeSchema.isValid(
            {
                verificationCode,
            },
            { abortEarly: false }
        )

        if (!validCode) {
            return verificationCodeSchema
                .validate({ verificationCode }, { abortEarly: false })
                .then(() => setVerificationCodeFormErrors([]))
                .catch((e) => setVerificationCodeFormErrors(e.inner))
        }

        setLoading(true)

        try {
            await window.confirmationResult.confirm(verificationCode)
            setIsLoginSuccess && setIsLoginSuccess(true)
        } catch (e: any) {
            if (e?.code === 'auth/invalid-verification-code') {
                const invalidCodeError: yup.ValidationError = {
                    name: 'Invalid Verification Code',
                    path: 'verificationCode',
                    message: 'Invalid verification code',
                    value: 'Invalid verification code',
                    inner: [],
                    errors: [],
                }
                setVerificationCodeFormErrors([invalidCodeError])
                return
            }

            if (e?.code === 'auth/too-many-requests') {
                const invalidError: yup.ValidationError = {
                    name: '',
                    path: 'verificationCode',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                }
                setVerificationCodeFormErrors([invalidError])
                return
            }

            const generalError: yup.ValidationError = {
                name: '',
                path: 'verificationCode',
                message:
                    'Something went wrong. Please try again later or click Chat Now to get assistance from our GiftGuide.',
                value: 'general error',
                inner: [],
                errors: [],
            }
            setVerificationCodeFormErrors([generalError])
        }

        setLoading(false)
    }

    if (showVerificationScreen) {
        return (
            <>
                <p>
                    A verification code has been sent to {phoneNumber}. Please
                    check your inbox and enter the verification code below.
                </p>

                {/*
                        <AuthCode> component takes CSS classnames to customize the
                        container and inputs. The classes are declared in the global css file.
                    */}
                <AuthCode
                    onChange={(val: string) => {
                        setVerificationCode(val.trim())
                        clearFormErrorForField('verificationCode')
                    }}
                    containerClassName="authcode-container"
                    inputClassName="authcode-input"
                    allowedCharacters="numeric"
                />
                <FormErrorDisplay
                    formErrors={verificationCodeFormErrors}
                    path={'verificationCode'}
                />

                <Button
                    label={phoneVerificationText}
                    onClick={confirmCode}
                    width="full"
                    showArrow={true}
                    textTransform="uppercase"
                    css={{ margin: '36px auto' }}
                    disabled={loading || verificationCode.length < 6}
                />
                <StyledH6>
                    {"Didn't Receive A Code? "}
                    <button onClick={() => handleSignUp(true)}>
                        Resend Code
                    </button>
                </StyledH6>
                {resentVerification && (
                    <AlertWithIcon
                        iconName={resentVerification.type}
                        size={'sm'}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        {resentVerification.message}
                    </AlertWithIcon>
                )}
                <br />
                <div
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        margin: '1.5rem auto',
                        fontSize: '14px',
                    }}
                >
                    <p>
                        PerfectGift wants your gift experience to be as safe and
                        secure as possible. To facilitate that, we require an
                        account to be signed into or created to ensure you are
                        the only person accessing your gift and gift details.
                        Please see our{' '}
                        <a
                            href={'https://www.perfectgift.com/terms-of-use'}
                            target="_open"
                            style={{ color: 'var(--colors-primary' }}
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            href={'https://www.perfectgift.com/privacy-policy'}
                            target="_open"
                            style={{ color: 'var(--colors-primary' }}
                        >
                            Privacy Policy
                        </a>{' '}
                        for more details about our policies.
                    </p>
                </div>
            </>
        )
    }

    return (
        <>
            <TextInputWithLabel
                id={'phone-number'}
                label={'Phone number'}
                numFormat={'(###) ###-####'}
                value={phoneNumber}
                onChange={(e: BaseSyntheticEvent) => {
                    let trimmedValue = e.currentTarget.value.trim()
                    setPhoneNumber(trimmedValue)
                    clearFormErrorForField('phoneNumber')
                }}
                hasError={inputHasError(phoneNumberFormErrors, 'phoneNumber')}
            />
            <FormErrorDisplay
                formErrors={phoneNumberFormErrors}
                path={'phoneNumber'}
            />

            {loginType === NEW_LOGIN && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            marginTop: '2rem',
                            fontSize: '14px',
                        }}
                    >
                        <input
                            id="opt-in"
                            name="optIn"
                            type="checkbox"
                            checked={optIn}
                            onChange={(e: BaseSyntheticEvent) => {
                                setOptIn(e.target.checked)
                                clearFormErrorForField('optIn')
                            }}
                            style={{ transform: 'scale(1.5)' }}
                        />

                        <label htmlFor="opt-in">
                            I would like to receive gift updates from PG eGift
                            Texts at the phone number provided. Message
                            frequency may vary. Message and data rates may
                            apply. Reply HELP for help or STOP to cancel.
                            Contact{' '}
                            <a
                                href={'mailto: support@perfectgift.com'}
                                style={{ color: 'var(--colors-secondary' }}
                            >
                                support@perfectgift.com
                            </a>{' '}
                            via email or{' '}
                            <ButtonStyledAsAnchor
                                aria-label={'chat-now-button'}
                                onClick={() => toggleChatBot()}
                            >
                                Chat Now
                            </ButtonStyledAsAnchor>{' '}
                            for help.{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/terms-of-use'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/privacy-policy'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Privacy Policy
                            </a>{' '}
                        </label>
                    </div>
                    <FormErrorDisplay
                        formErrors={phoneNumberFormErrors}
                        path={'optIn'}
                    />
                </>
            )}

            <Button
                id={PHONE_SIGN_IN_BTN_ID}
                label={actionText}
                onClick={() => handleSignUp(false)}
                width="full"
                showArrow={true}
                css={{ marginTop: 36 }}
                disabled={loading}
                textTransform={'uppercase'}
            />
        </>
    )
}

export default PhoneNumber
